
import { useEffect, useState } from 'react'
import { useLoaderData } from "react-router-dom";

import '../styles/utils.css'

import main, {  _DateTime } from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";

import WorkshopPlaceholder from '../resources/systemIcons/workshop_placeholder.png'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewPelletProductionRecord from '../elements/view_pellet_production_record';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TransferPellets from '../elements/transfer_pellets'
import DownloadDoc from '../elements/download_document'
import TransferedPelletSales from '../tables/transfered_pellets_table'
import ViewPelletTransfer from '../elements/view_pellet_transfer';

import PelletProductionTable from '../tables/pellet_production_table'

import FilterPelletProduction from '../elements/filter_pellet_production_records'

 
export function loader({params}){
    console.log(params)

    return fetch(main.server + `/view_workshop_info?workshop=${params.id}`).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  

export default function ViewWorkShop(){

    const [data, ] = useState(useLoaderData())
    const [filled, setFilled ] = useState(false)

    const [start, setStart] = useState(false)
    const [shop_name, setShopName] = useState('')
    const [ street, setStreet] = useState('')
    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')
    const [description, setDescription ] = useState('')
    const [ cash_balance, setCashBalance ] = useState('')

    const [geo_coords, setGeoCoords] = useState('')
    const [ pellet_stock, setPelletStock ] = useState('')
    const [ shopImage, setShopImage ] = useState(false)
   
    const [ email, setEmail ] = useState('')
    const [ region, setRegion ] = useState('')
 
    const [ setSnack, setAlert, username,  , _actions_ ] = useOutletContext()

    const [date_created, setDateCreated] = useState('')


    const [created_by, setCreatedBy] = useState('')


    const [ distributed_stock, setDistributedStock ] = useState('')


    const [ transfer_pellets, setTransferPellets ] = useState(false)
    const [ transfer_pellet_amount, setPelletTransferAmount ] = useState('') 

    const [ transfer_description, setTransferDescription ] = useState('')

    const [transfer_center, setTransferCenter] = useState('')
    const [transfer_workshop, setTransferWorkshop] = useState('')

    const [transfer_center_uid, setTransferCenterUID] = useState('')
    const [transfer_workshop_uid, setTransferWorkshopUID] = useState('')
    const [ pelletTransferDate, setPelletTransferDate ] = useState(new Date())

    const [tab, setTab] = useState(1)   

    function modifyShop(elm){
        window.location.assign(`/workshops/modify_workshop/${elm.target.dataset.uid}`)
    }
    
    //function TransferFunds(){
    //    setTransferCash(true)
    //}


    function TransferPellets_(){
        const form = new FormData()

        form.append('date', pelletTransferDate.toDateString())
        form.append('source', data.UID)
        form.append('transactor', username)
        form.append('amount', transfer_pellet_amount)

        if(transfer_center_uid){
            form.append('center', transfer_center_uid)
        }
        if(transfer_workshop_uid){
            form.append('workshop', transfer_workshop_uid)
        }
      
        
        if(transfer_description){
            form.append('description', transfer_description)
        }




        fetch(main.server + '/transfer_pellets_from_workshop', {
            method: 'POST',
            body: form,
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'pellets transfered',
                    type:'success'
                })
                setTimeout(() => window.location.reload(),5000)
            }
            
        })
    }

    function closePelletTransfer(){
        setTransferPellets(false)
    }
  
    function setPelletAmount(amount){
        if((Number(amount) && Number(amount) <= Number(cash_balance)) || amount === '' ){
            setPelletTransferAmount(amount)
        }
    }

    function RecordPelletProduction(){
     

        window.location.assign(`/record_pellet_production/workshop=${data.name}&workshopID=${data.UID}`)
        
    }

    function DisbursePellets(){
     

        setTransferPellets(true)
        
    }




    const [ confrim_state, setConfirmState ] = useState(false)
    const [ confrim_function, setConfirmFunction ] = useState([])


    function _delete_workshop (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:data.UID}))
  
        fetch( main.server + '/delete_workshop',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setAlert({ message:'workshop deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.assign('/workshops') }, 3000)
          }
        })
    }



    function closeConfirmDialog(){
        setConfirmState(false)
        setConfirmFunction([])
    }
    
    const delete_messenger = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_workshop])
    }

    function accept(){
        confrim_function[0]()
    }

    function handleTabChange(event, new_value){
        
        if(new_value === 3){
            get_pellet_disbursment(getPelletTransferForm(0))
        }
        if(new_value === 2){
            get_pellet_production_records(getPelletProductionForm(0))
        }
        setTab(new_value)
    }





    const [ pelletProduction_startDate, setPelletProductionStartDate] = useState(new Date())
    const [ pelletProduction_endDate, setPelletProductionEndDate] = useState(new Date())
    const [ pelletProductionFilterByRecorder, setFilterProductionByRecorder ] = useState('')



    const [ pellet_production_dtf, setPelletProductionDTF] = useState(false)
    const [ pellet_production_page, setPelletProductionPage] = useState(0)
    const [ pellet_production_total,setPelletProductionTotal ] = useState({})
    const [ pellet_production,setPelletProduction ] = useState([])
    


    const [ filter_pellet_production_by_startDate, setFilterPelletProductionByStartDate] = useState(false)
    const [ filter_pellet_production_by_endDate, setFilterPelletProductionByEndDate ] = useState(false)

    const [ filter_pellet_production_by_recorder, setFilterPelletProductionByRecorder ] = useState(false)
    

    const [ view_pellet_production_data, set_pellet_production_data ] = useState({})
    const [ filter_pellet_production_state , setFilterPelletProduction ] = useState(false)
    const [ view_pellet_production_record, setViewPelletProductionRecord ] = useState(false)


    const [ downloader_state, setDownloaderState ]  = useState(false)





    function fetchMorePelletProductionRecords(){
        
        const form = getPelletProductionForm(pellet_production.length)
        setSnack({message:'Fetching records...'})
        get_pellet_production_records(form, true)
        
    }

    function FilterPelletProductionRecords(){
        setFilterPelletProduction(true)
    }

    function ViewPelletProduction(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletProductionRecord(true)
        console.log(key)
        set_pellet_production_data(pellet_production[key])
    }

     function getPelletProductionRecords(){
        const form = getPelletProductionForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_pellet_production_records(form)

        
    }
    function getPelletProductionForm(start){
    
        const d = {}
        const form = new FormData()

        d['start'] = start
        d['workshop'] = data.UID

        if(filter_pellet_production_by_startDate){
            d['start_date'] = pelletProduction_startDate.toDateString()
        }
        if(filter_pellet_production_by_endDate){
            d['end_date'] = pelletProduction_endDate.toDateString()
        }
        if(filter_pellet_production_by_recorder){
            d['recorded_by'] = pelletProductionFilterByRecorder
        }
        if(filter_pellet_production_by_startDate && filter_pellet_production_by_endDate){
            setPelletProductionDTF(true)
        }
        
        form.append('form', JSON.stringify(d))

        return form
    }

    function downloadPelletProductionRecords() {

        setDownloaderState(true)
        

        const form = getPelletProductionForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_pellet_production_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })


        
    }
    function get_pellet_production_records(form, append=false){
        fetch(main.server + '/get_pellet_production_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(!append){
                    setPelletProduction( res.data.list )
                    
                }else{
                    setPelletProduction( prev => [...prev , ...res.data.list] )
                }
                setPelletProductionTotal(res.data.totals)
            }
            
        })
    }

   
    
    function render_current_page(){
        if(tab == 1){
            return(
                <div className="shop_info_main">
                        
                    <div className="BtnListcontainer">
                       
                        {_actions_.indexOf('modify_workshop') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={modifyShop}>
                                    <div>modify workshop info</div>
                                </div>
                            </div>
                        :''}

                        {_actions_.indexOf('record_pellet_production') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={RecordPelletProduction}>
                                    <div>record pellet production</div>
                                </div>
                            </div>
                        :''}


                        {_actions_.indexOf('disburse_pellets') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={DisbursePellets}>
                                    <div>transfer pellets</div>
                                </div>
                                
                            </div>
                        :''}
                        
                        {_actions_.indexOf('delete_workshop') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined btn_red" data-uid={data.UID} onClick={delete_messenger}>
                                    <div>delete workshop</div>
                                </div>
                            </div>
                        :''}
                    
                    </div>
                    <div className="container">
                        <div className="container_heading">Workshop Info</div>
                        <div className="container_main">
                            <div className="info_value_pair">
                                    <div>Created by:</div>
                                <div>{created_by}</div>  
                            </div>
                            <div className="info_value_pair">
                                <div>Date created: </div>
                                <div>{_DateTime(date_created)}</div>
                            </div>
                            
                            <div className="info_value_pair">
                                <div>phone number 1:</div>
                                <div>{phone_number1}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>phone number 2:</div>
                                <div>{phone_number2}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Region:</div>
                                <div>{region}</div>
                            </div>
                                        
                            <div className="info_value_pair">
                                <div>Email:</div>
                                <div>{email}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Geo coordinates:</div>
                                <div>{geo_coords}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Street:</div>
                                <div>{street}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Description: </div>
                                <div>{description}</div>
                            </div>
                        </div>

                        

                        
                    </div>


                    <div className="container">
                        <div className="container_heading">Accounting</div>
                        <div className="container_main">
                            <div className="info_value_pair">
                                <div>Pellet stock: </div>
                                <div>{pellet_stock} kg</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Distributed pellets: </div>
                                <div>{distributed_stock} kg</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else if(tab === 2){
            return(
                <div className="table_page_main">
                    <div className="pagetableContainer">
                        <PelletProductionTable 
                            startDate={ pelletProduction_startDate } 
                            endDate={pelletProduction_endDate}  
                            dtf={pellet_production_dtf} 
                            page={pellet_production_page} 
                            setPage={setPelletProductionPage} 
                            total={pellet_production_total} 
                            sales={pellet_production} 
                            fetch_more={fetchMorePelletProductionRecords} 
                            filter={FilterPelletProductionRecords} 
                            view_record={ViewPelletProduction}
                            download={downloadPelletProductionRecords}
                        />
                    </div>
                </div>
            )
        }else if(tab === 3){
            return(
                <div className="table_page_main">
                    <div className="pagetableContainer">
                        <TransferedPelletSales 
                            title="Disbursed pellets"
                            startDate={pelletTransferStartDate} 
                            endDate={pelletTransferEndDate}  
                            dtf={pellet_transfer_dtf} 
                            page={pellet_transfer_page} 
                            setPage={set_pellet_transfer_page} 
                            total={pellet_transfer_total} 
                            transfers={pellet_transfer} 
                            fetch_more={fetchMorePelletTransferRecords} 
                            filter={open_pellet_transfer_filter_} 
                            download={downloadPelletTransferRecords}
                            view_record={viewPelletTransferRecord}
                        />
                    </div>
                </div>
               
            )
        }
    }


    function close_pellet_production_filter(){
        setFilterPelletProduction(false)
    }

    function _delete_pellet_production (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_pellet_production_data.UID}))
  
        fetch( main.server + '/delete_pellet_production_record',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }

    const delete_messenger_pellet_production = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_pellet_production])
    }

    function closePelletProductionRecordViewer(){
        setViewPelletProductionRecord(false)
    }

    const modify_messenger_pellet_production = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_pellet_production(form)])
    }

    function _modify_pellet_production(form){
        console.log('last step', form)
        fetch(main.server + '/modify_pellet_production',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }





    const [pellet_transfer_dtf, setPelletTransferDTF] = useState(false)
    const [pelletTransferStartDate, setPelletTransferStartDate] = useState(new Date())
    const [pelletTransferEndDate, setPelletTransferEndDate] = useState(new Date())
    const [pelletTransferTransactor, setPelletTransferTransactor] = useState(['',''])
    const [pelletTransferShop, setPelletTransferShop] = useState(['',''])
 
    const [ open_pellet_transfer_filter, setPelletTransferFilterState ] = useState(false)


    const [pellet_transfer_page, setPelletTransferPage] = useState(0)
    const [ pellet_transfer_total, setPelletTransferTotal ] = useState({})
    const [ pellet_transfer, setPelletTransfers ] = useState([])

    const [filter_pellet_transfer_by_startdate, setFilterPelletTransferStartDate] = useState(false)
    const [filter_pellet_transfer_by_endDate, setFilterPelletTransferEndDate] = useState(false)
    const [filter_pellet_transfer_by_transactor, setFilterPelletTransferTransactor ] = useState(false)
    const [filter_pellet_transfer_by_shop, setFilterPelletTransferShop ] = useState(false)


    const [ view_pellet_transfer, setViewPelletTransferRecord ] = useState(false)
    const [ view_pellet_transfer_data, set_view_pellet_transfer_data ] = useState({})



    function get_pellet_disbursment(form){
        fetch(main.server + '/get_pellet_disbursment_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setPelletTransfers( res.data.list )
                setPelletTransferTotal(res.data.totals)
            }
            
        })
    }


    function getPelletTransferForm(start){
        const d = {}
        const form = new FormData()

        d['start'] = start
        d['workshop'] = data.UID

        if(filter_pellet_transfer_by_startdate){
            d['start_date'] = pelletTransferStartDate.toDateString()
        }
        if(filter_pellet_transfer_by_endDate){
            d['end_date'] = pelletTransferEndDate.toDateString()
        }
        if(filter_pellet_transfer_by_transactor){
            d['transactor'] = pelletTransferTransactor[0]
        }
        if(filter_pellet_transfer_by_shop){
            d['shop'] = pelletTransferShop[1]
        }
        if(filter_pellet_transfer_by_startdate && filter_pellet_transfer_by_endDate){
            setPelletTransferDTF(true)
        }
        
        form.append('form', JSON.stringify(d))

        return form
    }

    function downloadPelletTransferRecords() {
        console.log('downloading doc........')
        setDownloaderState(true)
        

        const form = getPelletTransferForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_pellet_transfer_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })


        
    }

    function viewPelletTransferRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletTransferRecord(true)
        set_view_pellet_transfer_data(pellet_transfer[key])
        
    }


    function open_pellet_transfer_filter_(){
        setPelletTransferFilterState(true)
    }

    function set_pellet_transfer_page(page){
        setPelletTransferPage(page)
    }

    function fetchMorePelletTransferRecords(){
        
        const form = getPelletTransferForm( pellet_transfer.length > 0 ? pellet_transfer.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_pellet_disbursment(form)
        
    }

    function _modify_pellet_cash_transfer(form){
        console.log('last step', form)


        fetch(main.server + '/modify_pellet_transfer_record',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }

    const modify_messenger_pellet_cash_transfer = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_pellet_cash_transfer(form)])
    }

    function closeViewPelletCashTransfer(){
        setViewPelletTransferRecord(false)
    }


    const delete_messenger_pellet_cash_transfer = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_pellet_cash_transfer])
    }



    function _delete_pellet_cash_transfer (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_pellet_transfer_data.UID}))
  
        fetch( main.server + '/delete_pellet_cash_transfer',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }else{
            setSnack({ message: res.message })
            setConfirmState(false)
            setConfirmFunction([])
        
          }
        })
    }


    useEffect(() => {
        if(!start){
            
            setSnack({
                message: 'page loaded',
            })

            setStart(true)
        }
        if(! filled){
            setShopName(data.name)
            setPhoneNumber1(data.phone_number1)
            setPhoneNumber2(data.phone_number2)
            setDescription(data.description)
            setGeoCoords(data.geo_coords)
            if(data.workshop_image){
                setShopImage(main.server + `/media/${data.workshop_image}`)
            }
            setEmail(data.email)
            setRegion(data.region)
            setCreatedBy(data.created_by)
            setDateCreated(data.date_created)
            setFilled(true)
            setStreet(data.street)
            setCashBalance(data.cash_balance)
            setPelletStock(data.pellet_stock)
            setDistributedStock(data.distributed)
        }
    })


    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>Workshop info</div>
                </div>

                <div>

                  

                    <div className="shopImage">
                        <img src={shopImage? shopImage: WorkshopPlaceholder} alt="workshop image"/>
                    </div>
                    
                    <div className="shop_main_info_container">
                        <div className="shopName">
                            <div>{shop_name}</div>
                        </div>
                        <div>
                            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab value={1} label="General"  />
                                <Tab value={2} label="Pellet production"  />
                                <Tab value={3} label="Pellet Disbursment"  />
                            
                            </Tabs>
                        </div>
                    </div>

                    <div>
                        {render_current_page()}
                    </div>

                    
                </div>
       


                <FilterPelletProduction

                    state={filter_pellet_production_state}


                    filter_pellet_sales_by_startDate={filter_pellet_production_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_production_by_endDate}
                    
                    filter_pellet_sales_by_recorder={filter_pellet_production_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterPelletProductionByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletProductionByEndDate}
                   
                    setFilterPelletSalesByRecorder={setFilterPelletProductionByRecorder}

                    pelletSales_startDate={pelletProduction_startDate}
                    setPelletSalesStartDate={setPelletProductionStartDate}

                    pelletSales_endDate={pelletProduction_endDate}
                    setPelletSalesEndDate={setPelletProductionEndDate}

                    pelletfilterByRecorder={pelletProductionFilterByRecorder}
                    setFilterPelletsByRecorder={setFilterProductionByRecorder}

 
                    getPelletSalesRecords={getPelletProductionRecords}
                    setPage={setPelletProductionPage} 

                    close_pellet_records_filter={close_pellet_production_filter}
                />


                <DownloadDoc 
                    state={downloader_state} 
                    setDownloaderState={setDownloaderState}
                    
                />

                <ViewPelletProductionRecord 
                    state={view_pellet_production_record}
                    close={closePelletProductionRecordViewer}
                    data={view_pellet_production_data}
                    alert={setAlert}
                    actions={_actions_}
                    snack={setSnack}
                    modify_messenger={modify_messenger_pellet_production}
                    delete_messenger={delete_messenger_pellet_production}

                />





                <TransferPellets
                    state={transfer_pellets}
                    title="Transfer pellets"
                    balance={pellet_stock}
                    amount={transfer_pellet_amount}
                    setAmount={setPelletTransferAmount}
                    action="transfer pellets"
                    close={closePelletTransfer}
                    accept={TransferPellets_}
                    description={transfer_description}
                    setDescription={setTransferDescription}
                    transfer_center={transfer_center}
                    setTransferCenter={setTransferCenter}
                    setTransferCenterUID={setTransferCenterUID}

                    transferDate={pelletTransferDate}
                    setTransferDate={setPelletTransferDate}

                    transfer_workshop={transfer_workshop}
                    setTransferWorkshop={setTransferWorkshop}
                    setTransferWorkshopUID={setTransferWorkshopUID}


                    transfer_center_uid={transfer_center_uid}
                />



                <ViewPelletTransfer
                    state={view_pellet_transfer}
                    title="Pellet cash transfer record"
                    close={closeViewPelletCashTransfer}
                    data={view_pellet_transfer_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_pellet_cash_transfer}
                    delete_messenger={delete_messenger_pellet_cash_transfer}
                />

               
                <Dialog
                    open={confrim_state}
                    onClose={closeConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Action"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to perform this action ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog}>DECLINE</Button>
                        <Button onClick={accept}>
                            ACCEPT
                        </Button>
                    </DialogActions>
                </Dialog>
              
            </div>
        </Fade>
    )
}