import { useState } from "react"
import '../styles/utils.css'
import Fade from '@mui/material/Fade'
import TextField from '@mui/material/TextField';
import Logo from '../resources/images/logo.png'
import main from '../resources/code/utils'
import Snackbar from '@mui/material/Snackbar';

/*
export function loader(){
    return fetch(main.server + '/users').then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
          
            return res.data
        }
        
    })
      
}  
*/

export default function Login(props){
    

    const [ username, setUsername ] = useState('')
    const [ password, setPassword ]  = useState('')

    const timeout = 5000

    const [ snack, set_snack ] = useState({
        message: ''
    })


    function handleSnackClose(){
        setSnack({message:''})
    }

    function setSnack(data){
        set_snack(data)
        setTimeout(() => { handleSnackClose() }, timeout)
    }

    function handleUsername(event){
       
        setUsername(event.target.value)
    }
    function handlePassword(event){
       
        setPassword(event.target.value)
    }

  
    function login(){
        const form = new FormData()
        form.append('username', username)
        form.append('password', password)

        fetch(main.server + '/login',{
            method:"POST",
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
              
                props.setUser(res.data)

                setSnack({message: `Welcome ${username}`})
            }else{
                setSnack({message: 'Login attempt failed, check the username and password'})
            }
            
        })
    }
    
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevents default form submission behavior
        login(); // Calls the login function
    };
    
    
    
    return(
        <Fade in={true}>
           
            <div id="login_page_main" >
                <div id="loginSideBar">
                    <div id="sideBarMain">
                        <div id="sideBarlogoContainer">
                            <img src={Logo} alt="dorcas logo"/>
                        </div>
                        <div id="sidebarMainText">Clean cooking energy project managment system</div>
                    </div>
                </div>
                <div id="login_main">
                    <div id="login_welcome_main" className="width350">
                        <div id="login_welcome">Welcome back</div>
                        <div className="pad5">login to start your session</div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="padv20">
                        <TextField
                            autoFocus
                            value={username}
                            onChange={handleUsername}
                            className="text_field login_input"
                            label="Username"
                            variant="standard"
                        />
                        </div>
                        <div className="padv20">
                        <TextField
                            value={password}
                            onChange={handlePassword}
                            className="text_field login_input"
                            label="Password"
                            variant="standard"
                            type="password"
                        />
                        </div>
                        <div className="padv20">
                        {/* Button inside form will trigger submit on Enter */}
                            <button type="submit" id="loginBtn" className="width350">
                                Login
                            </button>
                        </div>
                    </form>
                    <div id="login_footer">Dorcas clean cooking energy project &#169; <span>{new Date().getFullYear()}</span></div>
                </div>

                <Snackbar
                    className="pushSnack"
                    anchorOrigin={{vertical:'top', horizontal: 'center' }}
                    open={snack.message !== ''}
                    onClose={handleSnackClose}
                    message={snack.message}
                
                />
            </div>
        </Fade>
    )
}