
import { useEffect, useState } from 'react'
import { useLoaderData } from "react-router-dom"
import '../styles/utils.css'
import main, { _DateTime } from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import { formatCash } from '../resources/code/utils'
import TransferStoreFunds from '../elements/transfer_funds';
import LocationImage from '../resources/systemIcons/location.png'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FilterPelletSalesRecords from '../elements/filter_pellet_sales_records'
import PelletSalesTable from '../tables/pellet_sales_table'
import ViewPelletSale from '../elements/view_pellet_sale_record'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TransferPellets from '../elements/transfer_pellets'


import DownloadDoc from '../elements/download_document'


export function loader({params}){
    console.log(params)

    return fetch(main.server + `/view_shop_info?shop=${params.id}`).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  

export default function ViewShopInfo(){
    
    const [ tab, setTab ] = useState(1)

    const [data,  ] = useState(useLoaderData())
    const [filled, setFilled ] = useState(false)

    const [start, setStart] = useState(false)
    const [shop_name, setShopName] = useState('')
    const [ street, setStreet] = useState('')
    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')

    const [ contact_person, setContactPerson] = useState('')
    const [ cp_phone1, setContactPersonPhone1 ] = useState('')
    const [ cp_phone2, setContactPersonPhone2 ] = useState('')

    const [description, setDescription ] = useState('')
    const [ cash_balance, setCashBalance ] = useState('')
    const [ stove_balance, setStoveBalance ] = useState('')

    const [geo_coords, setGeoCoords] = useState('')
    
    const [ shopImage, setShopImage ] = useState('')
   
    const [ email, setEmail ] = useState('')
    const [ region, setRegion ] = useState('')
 
    const [ setSnack, setAlert, username, , _actions_  ] = useOutletContext()

    const [date_created, setDateCreated] = useState('')



    const [created_by, setCreatedBy] = useState('')

    const [ collet_pellet_sales, setCollectPelletSales ] = useState(false)
    const [ collet_stove_sales, setCollectStoveSales ] = useState(false)
 
    const [ pellet_sales_transfer_amount, setCollectionAmountPellets ] = useState('') 
    const [ stove_sales_transfer_amount,setCollectionAmountStoves ] = useState('')
    const [ pellet_collection_description, setPelletCollectionDescription ] = useState('')
    const [ stove_collection_description, setStoveCollectionDescription ] = useState('')

    const [ store_commission, setStoreCommision ] = useState('')


    const [ filter_pellet_sales_by_startDate, setFilterPelletSalesByStartDate] = useState(false)
    const [ filter_pellet_sales_by_endDate, setFilterPelletSalesByEndDate ] = useState(false)
    const [ filter_pellet_sales_by_shop, setFilterPelletSalesByShop ] = useState(false)
    const [ filter_pellet_sales_by_recorder, setFilterPelletSalesByRecorder ] = useState(false)
   

    const [ pelletSales_startDate, setPelletSalesStartDate] = useState(new Date())
    const [ pelletSales_endDate, setPelletSalesEndDate] = useState(new Date())
    const [ pelletfilterByRecorder, setFilterPelletsByRecorder ] = useState('')
    const [ pelletfilterByShop, setFilterPelletsByShop ] = useState('')
   


    const [ pellet_dtf, setPelletDTF] = useState(false)



    const [ pellet_sales_page, setPelletSalesPage] = useState(0)
    const [ pellet_total,setPelletTotal ] = useState({})
    const [ pellet_sales,setPelletSales ] = useState([])
   
    const [ pellet_filterState, setPelletFilterState ] = useState(false)
    const [ view_pellet_sale, setViewPelletSale ] = useState(false)
    const [ view_pellet_sale_data, set_pellet_sale_data ] = useState({})


    const [ stoveSalesTransferDate, setStoveSalesTransferDate ] = useState(new Date()) 
    const [ pelletSalesTransferDate, setPelletSalesTransferDate ] = useState(new Date())
    const [ pelletTransferDate, setPelletTransferDate ] = useState(new Date())

    function modifyShop(elm){
        window.location.assign(`/shops/modify_shop_info/${elm.target.dataset.uid}`)
    }
    
    function CollectPelletSales(){
        setCollectPelletSales(true)
    }
    function CollectStoveSales(){
        setCollectStoveSales(true)
    }
    function CollectPelletSales_(){
        const form = new FormData()


        form.append('shop', data.UID)
        form.append('amount', pellet_sales_transfer_amount)
        form.append('transactor', username)
        form.append('description', pellet_collection_description)
        form.append('date', pelletSalesTransferDate.toDateString())


        fetch(main.server + '/collect_pellet_funds', {
            method: 'POST',
            body: form,
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'funds collected',
                    type:'success'
                })
                setTimeout(() => window.location.reload(),5000)
            }
            
        })
    }


    function CollectStoveSales_(){
        const form = new FormData()


        form.append('shop', data.UID)
        form.append('amount', stove_sales_transfer_amount)
        form.append('transactor', username)
        form.append('description', stove_collection_description)
        form.append('date', stoveSalesTransferDate.toDateString())


        fetch(main.server + '/collect_stove_funds', {
            method: 'POST',
            body: form,
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'funds collected',
                    type:'success'
                })
                setTimeout(() => window.location.reload(),5000)
            }
            
        })
    }
    
    function closePelletCashCollection(){
        setCollectPelletSales(false)
    }
    function closeStoveCashCollection(){
        setCollectStoveSales(false)
    }

    function setPelletCollectionAmount(amount){
       
        setCollectionAmountPellets(amount)
        
    }


    function setStoveCollectionAmount(amount){
        
        setCollectionAmountStoves(amount)
        
    }

    
    function recordPelletSales(){
     

        window.location.assign(`/record_pellet_sales/shop=${data.name}`)
        
    }
    
    const [ confrim_state, setConfirmState ] = useState(false)
    const [ confrim_function, setConfirmFunction ] = useState([])


    function _delete_shop(){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:data.UID}))
  
        fetch( main.server + '/delete_shop',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setAlert({ message:'shop deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.assign('/shops') }, 3000)
          }
        })
    }

   
    function accept(){
        confrim_function[0]()
    }


    function closeConfirmDialog(){
        setConfirmState(false)
        setConfirmFunction([])
    }
    
    const delete_messenger = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_shop])
    }

    function handleTabChange(event, new_value){
        /*if(new_value === 2){
            setStovePaymentPage(0)
            get_stove_payments_records(getStovePaymentForm(0))
        }*/
        if(new_value == 2){
            setPelletSalesPage(0)
            get_pellet_records(getPelletSalesForm(0))
        }
        setTab(new_value)
    } 

    const [ downloader_state, setDownloaderState ]  = useState(false)
    
    /*

    const [ filter_stove_payment_by_startDate, setFilterStovePaymentByStartDate] = useState(false)
    const [ filter_stove_payment_by_endDate, setFilterStovePaymentByEndDate ] = useState(false)
    const [ filter_stove_payment_by_client, setFilterStovePaymentByClient ] = useState(false)
    const [ filter_stove_payment_by_recorder, setFilterStovePaymentByRecorder ] = useState(false)
   

    const [ stove_payment_startDate, setStovePaymentStartDate] = useState(new Date())
    const [ stove_payment_endDate, setStovePaymentEndDate] = useState(new Date())
    const [ stovePaymentFilterRecorder, setFilterStovePaymentRecorder ] = useState('')
    const [ stovePaymentFilterClient, setFilterStovePaymentClient ] = useState('')
   


    const [ stove_payment_dtf, setStovePaymentDTF] = useState(false)



    const [ stove_payment_page, setStovePaymentPage] = useState(0)
    const [ stove_payment_total,setStovePaymentTotal ] = useState({})
    const [ stove_payments , setStovePayments ] = useState([])
   
    const [ filter_stove_payment_state , setFilterStovePayment ] = useState(false)

    const [ view_stove_payment_record, viewStovePaymentRecord ] = useState(false)
    const [ view_stove_payment_data, set_view_stove_payment_data ] = useState({})
    


    function viewStovePaymentRecord_(event){
        const key = event.target.parentElement.dataset.key
        viewStovePaymentRecord(true)
        console.log(key)
        set_view_stove_payment_data(stove_payments[key])
        
    }

    function FilterStovePaymentRecords(){
        setFilterStovePayment(true)
    }

    function downloadStovePaymentRecords() {

        setDownloaderState(true)
        

        const form = getStovePaymentForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_stove_payment_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })


        
    }

    function getStovePaymentForm(start){
        const d = {}
        const form = new FormData()

        d['start'] = start
        d['center'] = data.UID

        if(filter_stove_payment_by_startDate){
            d['start_date'] = stove_payment_startDate.toDateString()
        }
        if(filter_stove_payment_by_endDate){
            d['end_date'] = stove_payment_endDate.toDateString()
        }
        if(filter_stove_payment_by_client){
            d['client'] = stovePaymentFilterClient[1]
        }
        if(filter_stove_payment_by_recorder){
            d['recorded_by'] = stovePaymentFilterRecorder
        }
        if(filter_stove_payment_by_startDate && filter_stove_payment_by_endDate){
            setStovePaymentDTF(true)
        }
        
        form.append('form', JSON.stringify(d))

        return form
    }

    function fetchMoreStovePaymentRecords(){
        
        const form = getStovePaymentForm(stove_payments.length)
        setSnack({message:'Fetching records...'})
        get_stove_payments_records(form)
        
    }

    function get_stove_payments_records(form){
        fetch(main.server + '/get_stove_payment_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setStovePayments( res.data.list )
                setStovePaymentTotal(res.data.totals)
            }
            
        })
    }
    

    function viewStovePaymentRecord_(event){
        const key = event.target.parentElement.dataset.key
        viewStovePaymentRecord(true)
        console.log(key)
        set_view_stove_payment_data(stove_payments[key])
        
    }

    function getStovePaymentRecords(){
        const form = getStovePaymentForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_stove_payments_records(form)

        
    }

    function close_stove_payment_filter(){
        setFilterStovePayment(false)
    }

    */


 


    function fetchMorePelletSales(){
        
        const form = getPelletSalesForm(pellet_sales.length)
        setSnack({message:'Fetching records...'})
        get_pellet_records(form)
        
    }

    function getPelletSalesForm(start){
        const d = {}
        const form = new FormData()

        d['start'] = start
        d['shop'] = data.UID

        if(filter_pellet_sales_by_startDate){
            d['start_date'] = pelletSales_startDate.toDateString()
        }
        if(filter_pellet_sales_by_endDate){
            d['end_date'] = pelletSales_endDate.toDateString()
        }
        if(filter_pellet_sales_by_shop){
            d['shop'] = pelletfilterByShop[1]
        }
        if(filter_pellet_sales_by_recorder){
            d['recorded_by'] = pelletfilterByRecorder
        }
        if(filter_pellet_sales_by_startDate && filter_pellet_sales_by_endDate){
            setPelletDTF(true)
        }
        
        form.append('form', JSON.stringify(d))

        return form
    }

    function get_pellet_records(form){
        fetch(main.server + '/get_pellet_sales',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setPelletSales( res.data.list )
                setPelletTotal(res.data.total)
            }
            
        })
    }

    function FilterPelletSales(){
        setPelletFilterState(true)
    }

    function viewPelletSaleRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletSale(true)
        console.log(key)
        set_pellet_sale_data(pellet_sales[key])
        
    }

    function downloadPelletsalesRecords() {

        setDownloaderState(true)
        
        const form = getPelletSalesForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_pellet_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })
    }

    function getPelletSalesRecords(){
        const form = getPelletSalesForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_pellet_records(form)

        
    }

    function close_pellet_records_filter(){
        setPelletFilterState(false)
    }

    function viewPelletSaleRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletSale(true)
        console.log(key)
        set_pellet_sale_data(pellet_sales[key])
        
    }

    function renderPage(){
        if( tab === 1){
            return(
                <div className="shop_info_main">
                    
                    <div className='BtnListcontainer'>
                    
                            {_actions_.indexOf('modify_shops') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={modifyShop}>
                                    <div>modify shop info</div>
                                </div>
                            </div>
                        :''}
                        {_actions_.indexOf('delete shop') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined btn_red" data-uid={data.UID} onClick={delete_messenger} >
                                    <div>delete shop</div>
                                </div>
                            </div>
                        :''}
                        {_actions_.indexOf('transfer_money_to_bank') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={CollectPelletSales}>
                                    <div>collect pellet sales</div>
                                </div>
                            </div>
                        :''}
                        {_actions_.indexOf('transfer_money_to_bank') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={CollectStoveSales}>
                                    <div>collect stove sales</div>
                                </div>
                            </div>
                        :''}
                        {_actions_.indexOf('record_pellet_sales') !== -1 ? 
                            <div  className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={recordPelletSales}>
                                    <div>record pellet sales</div>
                                </div>
                            </div>
                        :''}
                        {_actions_.indexOf('disburse_pellets') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={DisbursePellets}>
                                    <div>transfer pellets</div>
                                </div>
                                
                            </div>
                        :''}
                         
                    

                    </div>
                    
                    <div className="container">
                    
                        <div className="container_main">
                            <div className="title_value_pair">
                                <div>Created by</div>
                                <div>{created_by}</div>  
                            </div>
                            <div className="title_value_pair">
                                <div>Date created </div>
                                <div>{_DateTime(date_created)}</div>
                            </div>
                            
                            <div className="title_value_pair">
                                <div>phone number 1</div>
                                <div>{phone_number1}</div>
                            </div>
                            <div className="title_value_pair">
                                <div>phone number 2</div>
                                <div>{phone_number2 ? phone_number2 : 'None'}</div>
                            </div>

                            
                        
                        </div>

                        

                        
                    </div>


                    <div className="container">
                    
                        <div className="container_main">
                            

                            <div className="title_value_pair">
                                <div>contact person</div>
                                <div>{contact_person ? contact_person : 'None'}</div>
                            </div>

                            <div className="title_value_pair">
                                <div>contact phone 1</div>
                                <div>{cp_phone1 ? cp_phone1 : 'None'}</div>
                            </div>

                            <div className="title_value_pair">
                                <div>contact phone 2</div>
                                <div>{cp_phone2 ? cp_phone2 : 'None'}</div>
                            </div>
                        
                        </div>

                        

                        
                    </div>

                    <div className="container">
                    
                        <div className="container_main">
                            
                            <div className="title_value_pair">
                                <div>Region</div>
                                <div>{region}</div>
                            </div>
                                        
                            <div className="title_value_pair">
                                <div>Email</div>
                                <div>{email}</div>
                            </div>
                            <div className="title_value_pair">
                                <div>Geo coordinates</div>
                                <div>{geo_coords}</div>
                            </div>
                            <div className="title_value_pair">
                                <div>Street</div>
                                <div>{street}</div>
                            </div>
                            <div className="title_value_pair">
                                <div>Description </div>
                                <div>{description ? description : 'None'}</div>
                            </div>
                        </div>

                        

                    </div>


                    <div className="container">
                    
                        <div className="container_main">
                            <div className="title_value_pair">
                                <div>Email</div>
                                <div>{email}</div>
                            </div>
                            <div className="title_value_pair">
                                <div>Geo coordinates</div>
                                <div>{geo_coords}</div>
                            </div>
                            <div className="title_value_pair">
                                <div>Street</div>
                                <div>{street}</div>
                            </div>
                            <div className="title_value_pair">
                                <div>Description </div>
                                <div>{description}</div>
                            </div>
                        

                        </div>
                    
                    </div>

                </div>
            )
        }
        
        /*else if( tab === 2){
            return(
                <div>
                    <StovePaymentTable
                        startDate={stove_payment_startDate} 
                        endDate={stove_payment_endDate}  
                        dtf={stove_payment_dtf} 
                        page={stove_payment_page} 
                        setPage={setStovePaymentPage} 
                        total={stove_payment_total} 
                        sales={stove_payments} 
                        fetch_more={fetchMoreStovePaymentRecords} 
                        filter={FilterStovePaymentRecords} 
                        view_record={viewStovePaymentRecord_}
                        download={downloadStovePaymentRecords}
                    />
                </div>
            )
        }*/
        else if( tab === 2){
            return(
                <div>
                    <PelletSalesTable 
                        startDate={ pelletSales_startDate } 
                        endDate={pelletSales_endDate}  
                        dtf={pellet_dtf} 
                        page={pellet_sales_page} 
                        setPage={setPelletSalesPage} 
                        total={pellet_total} 
                        sales={pellet_sales} 
                        fetch_more={fetchMorePelletSales} 
                        filter={FilterPelletSales} 
                        view_record={viewPelletSaleRecord}
                        download={downloadPelletsalesRecords}
                    />
                </div>
            )
        }
    }

    function closePelletRecordViewer(){
        setViewPelletSale(false)
    }

    function _modify_pellet_sale(form){
        console.log('last step', form)
        fetch(main.server + '/modify_pellet_sale',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }


    function _delete_pellet_sale (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_pellet_sale_data.UID}))
  
        fetch( main.server + '/delete_pellet_sale',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }



    const modify_messenger_pellet_sales = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_pellet_sale(form)])
    }

    const delete_messenger_pellet_sales = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_pellet_sale])
    }

    const [ transfer_pellets, setTransferPellets ] = useState(false)
    const [ transfer_pellet_amount, setPelletTransferAmount ] = useState('') 

    const [ transfer_description, setTransferDescription ] = useState('')

    const [transfer_center, setTransferCenter] = useState('')
    const [transfer_workshop, setTransferWorkshop] = useState('')
    const [transfer_center_uid, setTransferCenterUID] = useState('')
    const [transfer_workshop_uid, setTransferWorkshopUID] = useState('')


    const [ pellet_stock, setPelletStock ] = useState('')

    function TransferPellets_(){
        const form = new FormData()

        form.append('date', pelletTransferDate.toDateString())
        form.append('source', data.UID)
        form.append('transactor', username)
        form.append('amount', transfer_pellet_amount)

        if(transfer_center_uid){
            form.append('center', transfer_center_uid)
        }
        if(transfer_workshop_uid){
            form.append('workshop', transfer_workshop_uid)
        }
        
        
        
        if(transfer_description){
            form.append('description', transfer_description)
        }




        fetch(main.server + '/transfer_pellets_from_center', {
            method: 'POST',
            body: form,
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'pellets transfered',
                    type:'success'
                })
                setTimeout(() => window.location.reload(),5000)
            }
            
        })
    }

    function closePelletTransfer(){
        setTransferPellets(false)
    }


    function DisbursePellets(){
     

        setTransferPellets(true)
        
    }

   


    useEffect(() => {
        if(!start){
         
            setSnack({
                message: 'page loaded',
            })

            setStart(true)
        }
        if(! filled){
            setShopName(data.name)
            setPhoneNumber1(data.phone_number1)
            setPhoneNumber2(data.phone_number2)
            setDescription(data.description)
            setContactPerson(data.contact_person)
            setContactPersonPhone1(data.cp_phone1)
            setContactPersonPhone2(data.cp_phone2)
            setGeoCoords(data.geo_coords)
            setShopImage(data.shop_image)
            setEmail(data.email)
            setRegion(data.region)
            setCreatedBy(data.created_by)
            setDateCreated(data.date_created)
            setFilled(true)
            setStreet(data.street)
            setCashBalance(data.pellet_cash_balance)
            setStoveBalance(data.stove_cash_balance)
            setStoreCommision(data.commission_balance)
            setPelletStock(data.pellet_stock)
        }
      
    })
    
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>Shop information</div>
                </div>

               
                   

                <div>
                    {shopImage ? <div className="shopImage">
                        <img src={ main.server + `/media/${shopImage}`} alt="shop id image"/>
                    </div>:
                    <div className="PlaceholderShopImage">
                        <img src={LocationImage} alt="shop id image"/>
                    </div>}
                </div>
                
                <div className="centerPage">
                    
                        
                    <div className="flex_column_center">
                        <div className="shopName">{shop_name}</div>
                        
                            
                        <div className="flex_row flex_wrap">
                            <div className="shop_balance">
                                
                                <div className="title_value_pair">
                                    <div>pellet stock balanace </div>
                                    <div>{pellet_stock} kg</div>
                                </div>

                                

                            </div>

                            <div className="shop_balance">
                                
                                <div className="title_value_pair">
                                    <div>pellet sales balanace </div>
                                    <div>{formatCash(cash_balance)}</div>
                                </div>

                                

                            </div>

                            <div className="shop_balance">
                                
                                <div className="title_value_pair">
                                    <div>stove sales balanace </div>
                                    <div>{formatCash(stove_balance)}</div>
                                </div>

                                

                            </div>

                            <div className="shop_balance">
                            
                                <div className="title_value_pair">
                                    <div>store commision balanace </div>
                                    <div>{formatCash(store_commission)}</div>
                                </div>

                            </div>
                            
                        </div>
                    </div>

                    <div className="centeredTabs spaceOutTabs">
                        <Tabs className='autoTabBody'  value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab value={1} label="General"  />
                            <Tab value={2} label="Pellet sales"  />
                        </Tabs>
                    </div>
                    
               
                    <div >
                        {renderPage()}
                    </div>
                
                </div>
                  
       
       

               <TransferStoreFunds 
                    
                    state={collet_pellet_sales}

                    transactionDate={pelletSalesTransferDate}
                    setTransactionDate={setPelletSalesTransferDate}
                    
                    title="Collect pellet sales"
                    action="Collect sales"
                    balance={cash_balance}
                    amount={pellet_sales_transfer_amount}
                    setAmount={setPelletCollectionAmount}
                    description={pellet_collection_description}
                    setDescription={setPelletCollectionDescription}
                    close={closePelletCashCollection}
                    accept={CollectPelletSales_}
               />


                <TransferStoreFunds 
                    
                    state={collet_stove_sales}
                    
                    transactionDate={stoveSalesTransferDate}
                    setTransactionDate={setStoveSalesTransferDate}

                    title="Collect Stove sales"
                    action="Collect sales"
                    balance={stove_balance}
                    amount={stove_sales_transfer_amount}
                    setAmount={setStoveCollectionAmount}
                    description={stove_collection_description}
                    setDescription={setStoveCollectionDescription}
                    close={closeStoveCashCollection}
                    accept={CollectStoveSales_}
                />
               

                <DownloadDoc 
                    state={downloader_state} 
                    setDownloaderState={setDownloaderState}
                    
                />


               
                
                <FilterPelletSalesRecords 
                    state={pellet_filterState}

                    filter_pellet_sales_by_startDate={filter_pellet_sales_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_sales_by_endDate}
                    filter_pellet_sales_by_shop={filter_pellet_sales_by_shop}
                    filter_pellet_sales_by_recorder={filter_pellet_sales_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterPelletSalesByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletSalesByEndDate}
                    setFilterPelletSalesByShop={setFilterPelletSalesByShop}
                    setFilterPelletSalesByRecorder={setFilterPelletSalesByRecorder}

                    pelletSales_startDate={pelletSales_startDate}
                    setPelletSalesStartDate={setPelletSalesStartDate}

                    pelletSales_endDate={pelletSales_endDate}
                    setPelletSalesEndDate={setPelletSalesEndDate}

                    pelletfilterByRecorder={pelletfilterByRecorder}
                    setFilterPelletsByRecorder={setFilterPelletsByRecorder}

                    pelletfilterByShop={pelletfilterByShop}
                    setFilterPelletsByShop={setFilterPelletsByShop}

                    
                    getPelletSalesRecords={getPelletSalesRecords}
                    setPage={setPelletSalesPage} 

                    close_pellet_records_filter={close_pellet_records_filter}
                />

                <TransferPellets
                    state={transfer_pellets}
                    title="Transfer pellets"
                    balance={pellet_stock}
                    amount={transfer_pellet_amount}
                    setAmount={setPelletTransferAmount}
                    action="transfer pellets"
                    close={closePelletTransfer}
                    accept={TransferPellets_}
                    description={transfer_description}
                    setDescription={setTransferDescription}

                    transferDate={pelletTransferDate}
                    setTransferDate={setPelletTransferDate}

                    transfer_center={transfer_center}
                    setTransferCenter={setTransferCenter}
                    setTransferCenterUID={setTransferCenterUID}
                    
                    transfer_workshop={transfer_workshop}
                    setTransferWorkshop={setTransferWorkshop}
                    setTransferWorkshopUID={setTransferWorkshopUID}

                    transfer_center_uid={transfer_center_uid}
                />


                <ViewPelletSale 
                    state={view_pellet_sale}
                    close={closePelletRecordViewer}
                    data={view_pellet_sale_data}
                    alert={setAlert}
                    snack={setSnack}
                    _actions_={_actions_}
                    modify_messenger={modify_messenger_pellet_sales}
                    delete_messenger={delete_messenger_pellet_sales}
                />


                    

                <Dialog
                    open={confrim_state}
                    onClose={closeConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Action"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to perform this action ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog}>DECLINE</Button>
                        <Button onClick={accept}>
                            ACCEPT
                        </Button>
                    </DialogActions>
                </Dialog>
              
            </div>


            
        </Fade>
    )
}