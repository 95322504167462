import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../styles/utils.css'
import '../styles/users.css'
import { format_cash, formatCash } from '../resources/code/utils'
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



export default function TransferStoreFunds(props){

    const balance = Number(props.balance)

    const [ start, setStart ] = useState(false) 


    
    function handleAmount(event){   


        let value = event.target.value


        if(value.search(',') !== -1){
            let clean = value.replaceAll(',','')
            if(Number(clean) || clean === '' || clean === '0'){
                
                clean = Number(clean)
                props.setAmount(clean)
            }
        }else{
            if(Number(value) || value === '' || value === '0'){
                value = Number(value)
                props.setAmount(value)
            } 
        } 
        
      
    }
    function updateDescription(event){
        props.setDescription(event.target.value)
    }
    function accept(){
        props.accept()
    }



    return(
        <Dialog
            open={props.state}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <div className='pad10'>
                    <div className="info_value_pair padv10">
                        <div>Balanace: </div>
                        <div>{formatCash(balance)}</div>
                    </div>
                    <div className="pad10">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Transaction date"
                               
                                value={props.transactionDate}
                                variant="standard"
                                onChange={(newstartDate) => {
                                    props.setTransactionDate(newstartDate)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="pad10">
                        <TextField value={format_cash(props.amount)} onChange={handleAmount} sx={{width: 250}} className="text_field" id="standard-basic" label="Amount" variant="standard" />
                    </div>
                    <div className="pad10">
                        <TextField
                            sx={{width: 250}}
                            className="text_field"
                            id="standard-multiline-static"
                            label="transfer description"
                            multiline
                            rows={3}
                        
                            value={props.description}
                            variant="standard"
                            onChange={updateDescription}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
              
                <Button onClick={accept}>
                    {props.action}
                </Button>
            </DialogActions>
        </Dialog>
    )
}