import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { _DateTime, _Date } from '../resources/code/utils'
import main from '../resources/code/utils'

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import TextField from '@mui/material/TextField';


export default function ViewPelletProductionRecord(props) {



    const [ amount, setAmount ] = useState('')
    const [record_id, setRecordID ] = useState('')
    const [start, setStart] = useState(false)
    const [ mod_record, setModRecord ] = useState(false)
    const [branch_list, setBranchList] = useState([])
    const [workshop, setWorkShop] = useState('')

    function closeViewer(){

      setWorkShop('')
      setAmount('')
      setRecordID('')
      setModRecord(false)
      props.close(false)
    }



    function delete_record(){
      props.delete_messenger()      
    }



    
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

   



    function modify_stove_sale(){
      const form = new FormData()
  
      const f = {

        workshop: workshop,
        amount:amount,
        UID: record_id,
      }
      
  
      form.append('form', JSON.stringify(f))

      props.modify_messenger(form)

    }

    function searchForBranches(elm){
      setWorkShop(elm.target.value)
      setAnchorEl(elm.currentTarget)
      fetch(main.server + `/workshops?q=${elm.target.value}`).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
      }).then(res => {
          if(res.request_status){
              setBranchList(res.data.workshops)
          }
      })
  }

    function selectBranch(name, uid){
      setWorkShop(name)
      setAnchorEl(null)
    }

    
    function modifyRecord(){
      setModRecord(true)
      setWorkShop(props.data.workshop)
      setAmount(props.data.amount)
      setRecordID(props.data.UID)
    }
    function back(){
      setModRecord(false)
      setWorkShop('')
      setAmount('')
      setRecordID('')
    }

    function handleAmount(event){
      setAmount(event.target.value)
    }
   

 
    useEffect(() => {
      if(!start){
       
        setStart(true)
      }
    })
  

    return (
        
        <Dialog
            open={props.state}
            onClose={closeViewer}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
            Pellet Production Info
        </DialogTitle>
        <DialogContent>
          {!mod_record?
          <div>
            <div>
              
              <div className="info_value_pair">
                <div>Date: </div>
                <div>{_Date(props.data.date)}</div>
              </div>
              <div className="info_value_pair padv10">
                <div>Start: </div>
                <div>{props.data.start}</div>
              </div>
              <div className="info_value_pair padv10">
                <div>End: </div>
                <div>{props.data.end}</div>
              </div>
              <div className="info_value_pair padv10">
                <div>Power source: </div>
                <div>{props.data.power_source}</div>
              </div>
              <div className="info_value_pair padv10">
                <div>Diesel added: </div>
                <div>{props.data.diesel_added ? props.data.diesel_added : '-'}</div>
              </div>
              <div className="info_value_pair padv10">
                <div>Workshop: </div>
                <div>{props.data.workshop}</div>
              </div>
              <div className="info_value_pair">
                <div>Amount Produced: </div>
                <div>{props.data.amount_produced} kg</div>
              </div>
              <div className="info_value_pair">
                <div>Amount Produced With Moisture: </div>
                <div>{props.data.amount_produced_with_moisture} kg</div>
              </div>
              <div className="info_value_pair">
                <div>Biomass Produced: </div>
                <div>{props.data.biomass} kg</div>
              </div>
              <div className="info_value_pair">
                <div>Recorded by: </div>
                <div>{props.data.recorded_by}</div>
              </div>
              <div className="info_value_pair">
                <div>Date time created: </div>
                <div>{_DateTime(props.data.date_created)}</div>
              </div>
              <div className="info_value_pair">
                <div>Description: </div>
                <div>{props.data.description ? props.data.description : '-'}</div>
              </div>
             
             

             

            </div>


            

            <div className="padv20 viewRecordActions">
              {props.actions.indexOf('modify_pellet_production_records') !== -1 ?
                <div className='btn btn_aqua' onClick={modifyRecord}>
                  <div>modify record</div>
                </div>
              :''} 

              {props.actions.indexOf('delete_pellet_production_records') !== -1 ? 
                <div className='btn btn_red' onClick={delete_record}>
                  <div>delete record</div>
                </div>
              :''} 
              
             
            </div>


          </div>
          :

          <div>
            <div className="form_main">
                        
              <div className="form_section">
              
                <div className="form_section_main">

                  <div className="flex_row ">
                    <TextField value={workshop} onChange={searchForBranches} sx={{width: 250}} className="text_field" id="standard-basic" label="Workshop" variant="standard" />

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >  
                        {branch_list.map((elm,ind) => (
                            <MenuItem key={ind} onClick={() => selectBranch(elm.name, elm.UID)}>{elm.name}</MenuItem>
                        ))}
                    </Menu>
                  </div>
                  
                  <div><TextField value={amount} onChange={handleAmount} sx={{width: 250}} className="text_field" id="standard-basic" label="Amount" variant="standard" /></div>
                  
                </div>

              </div>  
              
              

            </div>
            <div className="padv20 viewRecordActions">
              <div className='btn ' onClick={back}>
                <div>back</div>
              </div>
              <div className='btn btn-success' onClick={modify_stove_sale}>
                <div>modify record</div>
              </div>
                
            </div>
          </div>
        }
        </DialogContent>
        <DialogActions>
            <Button onClick={closeViewer}>
              Close
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}